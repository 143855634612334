@import './loader.css';
@import './scroll.css';
@import './content.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --navbarHeight: 3.5rem;
  --footerHeight: 3.5rem;
  --screenHeight: 100vh;
  --safe-area-inset-top: max(8px, env(safe-area-inset-top));
  --safe-area-inset-bottom: calc(
    var(--footerHeight) + max(8px, env(safe-area-inset-bottom))
  );
  --map-height: calc(var(--screenHeight) - var(--safe-area-inset-bottom));
}

@supports (height: 100svh) {
  :root {
    --screenHeight: 100svh;
  }
}

@media (display-mode: fullscreen) {
  :root {
    --screenHeight: 100vh;
    --navbarHeight: calc(3.5rem + env(safe-area-inset-top));
    --safe-area-inset-top: var(--navbarHeight);
    --map-height: calc(
      var(--screenHeight) - var(--safe-area-inset-bottom) - 8px
    );
    --pageHeight: calc(var(--screenHeight) - var(--safe-area-inset-bottom));
  }
  html {
    height: 100vh;
  }
}

:root {
  --safe-area-inset-top: var(--navbarHeight);
  --pageHeight: calc(
    var(--screenHeight) - var(--safe-area-inset-top) -
      var(--safe-area-inset-bottom)
  );
}

@screen sm {
  :root {
    --safe-area-inset-top: calc(
      var(--navbarHeight) + max(8px, env(safe-area-inset-top))
    );
  }
}

:root {
  /* #F3F3F3 */
  --background: 243 243 243;
  /* #131215 */
  --dark: 19 18 21;
  /* #242F3E */
  --primary: 36 47 62;
  /* #171C26 */
  --primaryDark: 23 28 38;
  /* #935D4E*/
  --secondary: 147 93 78;
  /* #6E4E49 */
  --secondaryDark: 110 78 73;
  /* #FFFFFF */
  --white: 255 255 255;
  /* #FFFFFF */
  --backgroundLight: 255 255 255;
  /* #e4e4e7 */
  --skeleton: 228 228 231;
}

:root body.dark {
  /* #141414 */
  --background: 20 20 20;
  /* #131215 */
  --dark: 19 18 21;
  /* #EEE8D3 */
  --primary: 238 232 211;
  /* #161C27 */
  --primaryDark: 226 217 182;
  /* #E2D9B6 */
  --secondary: 147 93 78;
  /* #6E4E49 */
  --secondaryDark: 110 78 73;
  /* #FFFFFF */
  --white: 255 255 255;
  /* #1F1F1F */
  --backgroundLight: 31 31 31;
  /* #27272a */
  --skeleton: 39 39 42;
}

/* force icons to have 1px stroke */
svg {
  stroke-width: 1px;
}

/* fix for iOS for different text color in disabled inputs */
input:disabled,
textarea:disabled,
input:disabled::placeholder,
textarea:disabled::placeholder {
  -webkit-text-fill-color: currentcolor;
  opacity: 1;
}

details summary::-webkit-details-marker {
  display: none;
}

/* disable outline on google maps markers */
#google-map [role='button']:focus-visible,
#google-map [role='button'] *:focus-visible {
  outline: none;
}

/* disable outline on google maps map */
#google-map iframe + div {
  outline: none;
  border: none !important;
}
